import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderFour from '../common/header/HeaderFour';
import SEO from '../common/SEO';
import BannerFive from '../component/banner/BannerFive';
import AboutFive from '../component/about/AboutFive';
import { useTranslation } from 'react-i18next';
import { useArrayTranslations } from '../hooks/useArrayTranslations';

import Cards from '../common/cards/Cards';
import ValueCard from '../common/cards/ValueCard';
import useAssets from '../hooks/useAssests';


const OurVision = () => {
    const { t } = useTranslation('our_vision');

    const { getTranslations } = useArrayTranslations('values');
    const items = getTranslations();
    const { assets } = useAssets('our_vision');




    return (
        <>
            <SEO title={t('our_vision')} />
            <main className="main-wrapper">
                <HeaderFour />
                <BannerFive show_more={false} bk_image={assets.hero_image} />
                <div className='section section-padding container'>
                    <div className='row'>
                        <div className='col-12'>
                            <h3 className='title' style={{color : '#AD942D'}}>{t('our_vision_forward')}</h3>
                            <h4 className='title'>{t('our_vision')}</h4>
                        </div>
                        <AboutFive title={t('our_vision')} paragraph={t('our_vision_paragraph')} />
                        <div className='row'>
                            <div className='col-12'>
                                <h4 className='title'>{t('our_mission')}</h4>
                            </div>
                        </div>
                        <AboutFive title={t('our_mission')} paragraph={t('our_mission_paragraph')} />
                        <div className='row'>
                            <div className='col-12'>
                                <h4 className='title'>{t('our_values')}</h4>
                            </div>
                        </div>
                        <Cards data={items} CardComponent={ValueCard} />


                    </div>

                </div>
                <FooterOne parentClass="" />
            </main>
        </>
    )
}

export default OurVision;