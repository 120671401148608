import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderFour from '../common/header/HeaderFour';
import SEO from '../common/SEO';
import BannerFive from '../component/banner/BannerFive';
import AboutFive from '../component/about/AboutFive';
import ProjectFive from '../component/project/ProjectFive';
const SocialResponsibility = () => {

    return (
        <>
            <SEO title="Social Responsibility" />
            <main className="main-wrapper">
                <HeaderFour />
                <BannerFive show_more={false}/>
                <div className='section section-padding container'>
                    <div className='row'>
                        <div className='col-12'>
                            <h5 className='title'>المسؤولية الاجتماعية</h5>
                            <h4 className='title'>مبادرات المسؤولية الاجتماعية لشركة لمار</h4>
                        </div>
                
                        <ProjectFive page='social-responsibility'/>
                    </div>


                </div>
                <FooterOne parentClass="" />
            </main>
        </>
    )
}

export default SocialResponsibility;