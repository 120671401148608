import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';


const data = {

    "id": 1,
    "thumb": "/images/others/case-study-1.png",
    "title": "about_the_company",
    "subtitle": "",
    "count_roi": "15",
    "count_visit": "60",
    "excerpt": "paragraph2",
    // "excerpt": "منذ أن قام معالي الأستاذ صالح علي التركي بتأسيس شركة لمار عام 1979 وهي تؤدي دورًا هامًا في تطوير وتنويع الاقتصاد السعودي وتعزيز الممارسات التجارية الموثوقة، و تسترشد الشركة في الوقت الحاضر برؤية السعودية 2030. تُمثل قيَم التميز والابتكار والتنوع والاحترام وترك الأثر نهجًا للمار، كما تلتزم الشركة بأن تكون شريك عمل ومصدرًا للتوظيف ذات تميُّز ومصداقية. ",
    // "excerpt_2": "يشكل الموظفون الذين يتمتعون بالكفاءة والخبرات المتنوعة جزءًا أساسيًا من هوية شركاتنا. وبينما نحرص على تقديم فرص قيّمة للمواطنين تلبي طموحاتهم وأهدافهم المهنية، يتسع نطاق مساهماتنا المجتمع بأسره عبر تمويل برامج التوظيف وتوفير فرص العمل في جميع أنحاء المملكة.",
    // "excerpt_3": "تتميز شركة لمار بنطاق أعمالٍ واسع ومتنوع ومرنٍ، وبسجلٍ حافل بالنجاحات في تطوير الأعمال والتعرف على القطاعات الجديدة ذات الإمكانيات الكبيرة، والدخول إليها. وإننا نسعى في لمار إلى بناء الشراكات الهادفة، مستندين في ذلك إلى ما نتمتع به من فطنة تجارية وقوة مالية وقدرات تتيح لنا تنفيذ المشاريع الطموحة. وقد تمكنّا من إرساء قيم النزاهة والاستقرار في جميع تعاملاتنا بفضل الإدارة السليمة، وجهود أعضاء فريقنا واسعي الخبرة، والتزامنا بمبدأ الامتثال الذي يشمل التدقيق الداخلي وإدارة المخاطر.",
}

const CaseStudyProp = () => {

    const { t } = useTranslation('details');

    return (
        <div className="row" key={data.id}>
            <div className="col-lg-6" >
                <div className={`case-study-featured-thumb thumb-${data.id}`}>
                    <img src={process.env.PUBLIC_URL + data.thumb} alt="Case Study" style={{ marginTop: '30px' }} />
                </div>
            </div>
            <div className="col-xl-5 col-lg-6 offset-xl-1">
                <div className="case-study-featured">
                    <div className="section-heading heading-left">
                        <span className="subtitle">{t(data.subtitle)}</span>
                        <h2 className="title">{t(data.title)}</h2>
                        <p>{t(data.excerpt)}</p>
                        {/* <p>{data.excerpt_2}</p>
                        <p>{data.excerpt_3}</p> */}
                    </div>

                </div>
            </div>
        </div>
    )
}

// const CaseLeftThumb = ({ data }) => {
//     return (
//         <>
//             <div className="col-lg-6" >
//                 <div className={`case-study-featured-thumb thumb-${data.id}`}>
//                     <img src={process.env.PUBLIC_URL + data.thumb} alt="Case Study" style={{ marginTop: '30px' }} />
//                 </div>
//             </div>
//             <div className="col-xl-5 col-lg-6 offset-xl-1">
//                 <div className="case-study-featured">
//                     <div className="section-heading heading-left">
//                         <span className="subtitle">{data.subtitle}</span>
//                         <h2 className="title">{data.title}</h2>
//                         <p>{data.excerpt}</p>
//                         {/* <p>{data.excerpt_2}</p>
//                         <p>{data.excerpt_3}</p> */}
//                     </div>

//                 </div>
//             </div>
//         </>
//     )
// }

export default CaseStudyProp;