import { useState, useEffect } from 'react';
import getItems from '../utils/directus';

const useArray = (collection, language = null) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchdata = async () => {
            setLoading(true);
            const response = await getItems(collection, language);
            if (response) {
                setData(response);
            }
            setLoading(false);
        };

        fetchdata();
    }, [collection, language]);

    return { data, loading };
};

export default useArray;