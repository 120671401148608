import { useTranslation } from 'react-i18next';


// TODO: REname
export function useArrayTranslations(namespace) {
    const { t, i18n } = useTranslation(namespace);

    const getTranslations = () => {
        const resourceBundle = i18n.getResourceBundle(i18n.language, namespace);
        console.log({ resourceBundle });
        return Object.values(resourceBundle) || [];
    };

    return { t, getTranslations };
}