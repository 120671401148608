import React from 'react';
import { Link } from 'react-router-dom';
import FormOne from '../contact/FormOne';

import { useTranslation } from 'react-i18next';
import { IMAGES_URL } from '../../utils/constants';

const BannerFive = ({ title = null, subtitle = null, pargraph = null, pargraph2 = null, bk_image = null, show_more = true , to = null}) => {

    const { t } = useTranslation();

    return (
        <div className="banner banner-style-5" style={{ backgroundImage: bk_image && bk_image != '' ? `url(${IMAGES_URL + bk_image})` : `url(https://placehold.co/black)` }}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-xl-7">
                        <div className="banner-content">
                            <h1 className="title">
                                {title}
                                {subtitle && <h5 style={{
                                    color: '#fff',
                                    marginBottom: '20px'
                                }}>
                                    {subtitle}
                                </h5>}
                            </h1>

                            {pargraph && <p style={{
                                color: '#fff',
                                marginBottom: '20px'
                            }}>
                                {pargraph}
                            </p>}
                            {pargraph2 && <p style={{
                                color: '#fff',
                                marginBottom: '20px'
                            }}>
                                {pargraph2}
                            </p>}
                            <div>
                                {show_more && <Link /*to={process.env.PUBLIC_URL + "/project-grid-one"} */ to={'/'+to} className="axil-btn btn-bk">{t('show_more')}</Link>}

                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default BannerFive;