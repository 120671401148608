import React from 'react';
import FormOne from '../contact/FormOne';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa6';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useAssets from '../../hooks/useAssests';
import { IMAGES_URL } from '../../utils/constants';

const AboutOne = () => {

    const { t, i18n } = useTranslation()
    const { assets } = useAssets('home');

    const getBulletIcon = () => {
        const iconStyle = {
            color: '#AD942D',
            fontSize: '20px',
            position: 'relative',
            bottom: '2px',
            marginRight: i18n.language === 'en' ? '10px' : '0',
            marginLeft: i18n.language === 'ar' ? '10px' : '0'
        };

        return i18n.language === 'en' ? <FaAngleRight style={iconStyle} /> : <FaAngleLeft style={iconStyle} />;
    };

    return (
        <section className="section section-padding-equal bg-color-dark">
            <div className="container">
                <div className="row mb-3">
                    <div className='col-12'>
                        <h3 className="subtitle" style={{
                            color: '#AD942D',
                            marginBottom: '5px'
                        }}>{t('main_points')}</h3>
                        <h5 className="title mb--40" style={{
                            color: 'white'
                        }}>{t('our_legacy')}</h5>

                    </div>
                </div>
                <div className="row">

                    <div className="col-xl-6 col-lg-6 mb-4">
                        <img src={`${IMAGES_URL}/${assets.our_legacy_image}`} alt="media" />
                    </div>
                    <div className="col-lg-6">
                        <div className="about-us">
                            <div className="section-heading heading-left mb-0">

                                <h4 className="title mb--40" style={{
                                    color: 'white'
                                }}>{t('our_legacy')}</h4>
                                <span className="subtitle" style={{
                                    color: 'white',
                                    marginBottom: '10px'
                                }}>{t('excellency_mr')}</span>
                                <h4 style={{
                                    color: 'white',
                                    marginBottom: '10px'
                                }}>{t('lamar_ceo')}</h4>
                                <span className="subtitle" style={{
                                    color: 'white',
                                    marginBottom: '2px'
                                }}>{t('ceo')}</span>
                                <div style={{ position: 'relative', top: 20 }}>
                                    <ul
                                        style={{
                                            color: 'white',
                                            listStyle: 'none',
                                        }}
                                    >
                                        <li style={{ marginBottom: '20px', fontSize: '20px' }}>
                                            {getBulletIcon()}
                                            {t('legacy_paragraph_1')}
                                        </li>
                                        <li style={{ marginBottom: '20px', fontSize: '20px' }}>
                                            {getBulletIcon()}
                                            {t('legacy_paragraph_2')}
                                        </li>
                                        <li style={{ marginBottom: '20px', fontSize: '20px' }}>
                                            {getBulletIcon()}
                                            {t('legacy_paragraph_3')}
                                        </li>
                                    </ul>
                                    <Link to='#'
                                        style={{
                                            color: 'white',
                                            fontSize: '20px',
                                            paddingBottom: '5px',
                                            borderBottom: '1px solid white',
                                        }}
                                    >{t('open_cv')}</Link>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </section>
    )
}

export default AboutOne;