import React from 'react';
import SEO from '../common/SEO';
import FooterOne from '../common/footer/FooterOne';
import HeaderFour from '../common/header/HeaderFour';
import FormTwo from '../component/contact/FormTwo';
import BannerFive from '../component/banner/BannerFive';
import SectionTitle from '../elements/section-title/SectionTitle';
import { FaAngleDown } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import useAssets from '../hooks/useAssests';
import { IMAGES_URL } from '../utils/constants';
import  useArray  from '../hooks/useArray';

const Contact = () => {

    const { t } = useTranslation('contact_us');

    const { data } = useArray('phone_numbers');

    const { data: data2 } = useArray('emails');
    
    const { assets } = useAssets('contact_us');



    return (
        <>
            <SEO title={t('contact_us')} />

            <main className="main-wrapper">
                <BannerFive show_more={false} bk_image={assets.hero_image}/>

                <HeaderFour dark={true} />


                <div className="section section-padding">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-7 col-lg-6 mb--30">
                                <div className="col-lg-6">
                                    <div className="contact-address mb--30">
                                        <h3 style={{color : '#AD942D'}} className="title">{t('phones_and_addresses')}</h3>
                                        <h5 style={{
                                            color: '#8F8F8F'
                                        }}>{t('main_office')}<FaAngleDown style={{
                                            float: 'left',
                                        }} className='mt--10 mb--10' /> </h5>

                                        <div className="address-list">
                                            <div className="address">
                                                <p style={{
                                                    width: '100% ',
                                                    color: 'black'
                                                }}>{t('address')}</p>
                                                <p style={{
                                                    width: '100% ',
                                                    color: 'black'
                                                }}>{t('country')}</p>
                                            </div>
                                            <div className="address">
                                                <p style={{
                                                    width: '100% ',
                                                    color: 'black'
                                                }}>{t('phone_number')}</p>
                                                {
                                                    data.map((item, index) => (
                                                        <p key={index} style={{
                                                            width: '100% ',
                                                            color: 'black'
                                                        }}>{item.phone_number}</p>
                                                    ))
                                                }
                                            </div>
                                            <div className="address">
                                                <p style={{
                                                    width: '100% ',
                                                    color: 'black'
                                                }}>{t('email')}</p>
                                                {
                                                    data2.map((item, index) => (
                                                        <p key={index} style={{
                                                            width: '100% ',
                                                            color: 'black'
                                                        }}>{item.email}</p>
                                                    ))
                                                }
                                            </div>
                                            {/* <div className="address">
                                                <p
                                                    style={{
                                                        width: '100% ',
                                                        color: 'black'
                                                    }}
                                                >{t('fax')}</p>
                                            </div> */}
                                        </div>
                                    </div>
                                    {/* <img src={IMAGES_URL + assets.image} alt={t('contact')} /> */}
                                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3709.5457580302605!2d39.114305!3d21.6036471!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x15c3db8678d0cd03%3A0x5ef0bae90e9c4feb!2z2LTYsdmD2Kkg2YTZhdin2LEg2KfZhNi52KfZhNmF2YrYqSDYp9mE2YLYp9io2LbYqQ!5e0!3m2!1sen!2seg!4v1726084214437!5m2!1sen!2seg" width="600" height="450" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                </div>
                            </div>
                            <div className="col-xl-5 col-lg-6">
                                <div className="contact-form-box shadow-box mb--30">
                                    <h3 className="title mb--30"> {t('contact_us')}</h3>
                                    <FormTwo />
                                </div>
                            </div>

                        </div>
                    </div>

                </div>


                <FooterOne parentClass="pt--150 pt_lg--100 pt_md--80 pt_sm--60" />
            </main>
        </>
    )
}

export default Contact;