import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import getItems from './directus';


const manyTranslations = ['boards_members', 'values']

const directusBackend = {
  type: 'backend',
  init: () => { },
  read: async (language, namespace, callback) => {
    try {
      const items = await getItems(namespace, language);

      if (namespace.includes('samples') || manyTranslations.includes(namespace)) {

        callback(null, items);
        return;

      }


      const translations = items.translations?.[0]

      callback(null, translations);
    } catch (error) {
      console.error('Failed to fetch translations:', error);
      callback(error, null);
    }
  }
};

i18n
  .use(directusBackend)
  .use(initReactI18next)
  .init(
    {
      fallbackLng: 'ar',
      debug: true,
      ns: ['home', 'boards_members', 'our_company', 'company_samples', 'values', 'contact_us', 'details', 'footer', 'governance', 'navbar', 'news', 'news_samples', 'media_center', 'media_center_samples', 'our_legacy', 'our_vision', 'seo'],
      defaultNS: 'home',
      interpolation: {
        escapeValue: false, // React already does escaping
      },
    });




export default i18n;
