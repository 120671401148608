import { IMAGES_URL } from "../../utils/constants";

const ValueCard = ({ data }) => {
    const { image } = data;
    const { value, paragraph } = data?.translations?.[0] ?? {
        value: '',
        paragraph: ''
    };

    return (
        <div className="project-grid">
            <div>
                <img src={IMAGES_URL + image} alt="icon" />
                <div className="content">
                    <h5 className="title">
                        {value}
                    </h5>
                    <p>
                        {paragraph}
                    </p>
                </div>
            </div>
        </div>
    );
};

export default ValueCard;