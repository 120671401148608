import React from 'react';

import { useTranslation } from 'react-i18next'


const SectionTitle = ({ subtitle, title, description, textAlignment, textColor }) => {
    const { t } = useTranslation();

    return (
        <div className={`section-heading ${textAlignment} ${textColor}`}>
            <h3 className="subtitle nav_btn_color" dangerouslySetInnerHTML={{ __html: t(subtitle) }}></h3>
            <h5 className="title " dangerouslySetInnerHTML={{ __html: t(title) }}></h5>
            <p dangerouslySetInnerHTML={{ __html: t(description) }}></p>
        </div>
    )
}

export default SectionTitle;