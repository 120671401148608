import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderFour from '../common/header/HeaderFour';
import SEO from '../common/SEO';
import SectionTitle from '../elements/section-title/SectionTitle';
import ServiceProp from '../component/service/ServiceProp';
import marketingData from "../data/service/ServiceMain.json";
import BannerFive from '../component/banner/BannerFive';
import BrandItem from '../component/brand/BrandItem';
import CaseStudyProp from '../component/casestudy/CaseStudyProp';
import { useTranslation } from 'react-i18next';
import useAssets from '../hooks/useAssests';

const Details = () => {

    const { assets } = useAssets('details');

    
    
    const { t } = useTranslation('details');

    return (
        <>
            <SEO title={t("about_the_company")} />
            <main className="main-wrapper">
                <HeaderFour />
                <BannerFive show_more={false} bk_image={assets.hero_image}/>
                <div className="section section-padding" id="section3" >
                    <div className="container">
                        <SectionTitle
                            subtitle={t("about_the_company")}
                            title={t("paragraph1")}
                            description=""
                            textAlignment="heading-left"
                            textColor=""

                        />
                        <div className='row mb-4'>
                            <BrandItem />

                        </div>
                        <div className='row mt-4' >
                            <CaseStudyProp />
                        </div>

                    </div>
                </div>

                <FooterOne parentClass="" />
            </main>
        </>
    )
}

export default Details;