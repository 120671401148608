import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderFour from '../common/header/HeaderFour';
import SEO from '../common/SEO';
import BannerFive from '../component/banner/BannerFive';
import { useTranslation } from 'react-i18next';
import Cards from '../common/cards/Cards';

import { useArrayTranslations } from '../hooks/useArrayTranslations';
import useAssets from '../hooks/useAssests';

import MediaCard from '../common/cards/MediaCard';
const MediaCenter = () => {

    const { t } = useTranslation('media_center');

    const { getTranslations } = useArrayTranslations('media_center_samples');

    const items = getTranslations();
    const { assets } = useAssets('media_center');


    return (
        <>
            <SEO title={t('title')} />
            <main className="main-wrapper">
                <HeaderFour />
                <BannerFive show_more={false} bk_image={assets.hero_image} />
                <div className='section section-padding container'>
                    <div className='row'>
                        <div className='col-12'>
                            <h3 style={{color : '#AD942D'}} className='title'>{t('title')}</h3>
                        </div>

                        <Cards data={items} CardComponent={MediaCard} />
                    </div>


                </div>
                <FooterOne parentClass="" />
            </main>
        </>
    )
}

export default MediaCenter;