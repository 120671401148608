import SEO from '../common/SEO';
import BannerFive from '../component/banner/BannerFive';
import HeaderFour from '../common/header/HeaderFour';
import FooterOne from '../common/footer/FooterOne';
import ServicePropOne from '../component/service/ServicePropOne';
import SectionTitle from '../elements/section-title/SectionTitle';
import AboutOne from '../component/about/AboutOne';

import { useTranslation } from 'react-i18next';

import useAssets from '../hooks/useAssests';


const Home = () => {


  const { t } = useTranslation(['home', 'seo']);

  const { assets } = useAssets('home');


  return (
    <>
      <SEO title={t('seo:title')} />
      <main className="main-wrapper">
        <HeaderFour />
        <BannerFive to={assets.show_more_href} title={t('title')} bk_image={assets.header_background} />
        <div className="section section-padding">
          <div className="container"

          >
            <SectionTitle
              subtitle="about_lamar"
              title="what_we_offer"
              description=""
              textAlignment="heading-left mb--20 mb_md--70"
              textColor=""
            />
            <div className="row"

            >
              <ServicePropOne
                colSize="col-lg-4" serviceStyle="service-style-2" itemShow="3" marginTop="yes" />
            </div>

          </div>


        </div>
        <AboutOne />

        {/* <ProjectOne colSize="col-xl-4 col-md-6" columnGap="row-15" parentClass="bg-color-light" /> */}

        <FooterOne />
      </main>
    </>
  )
}

export default Home;