import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderFour from '../common/header/HeaderFour';
import SEO from '../common/SEO';
import BannerFive from '../component/banner/BannerFive';
import { useTranslation } from 'react-i18next';
import { useArrayTranslations } from '../hooks/useArrayTranslations';
import Cards from '../common/cards/Cards';
import NewsCard from '../common/cards/NewsCard';
import useAssets from '../hooks/useAssests';


const LatestNews = () => {

    const { t } = useTranslation('news');

    const { getTranslations } = useArrayTranslations('news_samples');

    const items = getTranslations();

    const { assets } = useAssets('news');


    return (
        <>
            <SEO title={t('title')} />
            <main className="main-wrapper">
                <HeaderFour />
                <BannerFive
                    show_more={false}  bk_image={assets.hero_image}/>
                <div className='section section-padding container'>
                    <div className='row'>
                        <div className='col-12'>
                            <h3 style={{color : '#AD942D'}} className='title'>{t('title')}</h3>
                        </div>
                    </div>

                    <Cards data={items} CardComponent={NewsCard} />

                </div>

                <FooterOne parentClass="" />
            </main>
        </>
    )
}

export default LatestNews;