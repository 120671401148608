import { useState, useEffect } from 'react';
import getItems from '../utils/directus';

const useAssets = (collection, language = null) => {
    const [assets, setAssets] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchAssets = async () => {
            setLoading(true);
            const response = await getItems(collection, language);
            if (response) {
                setAssets(response);
            }
            setLoading(false);
        };

        fetchAssets();
    }, [collection, language]);

    return { assets, loading };
};

export default useAssets;