import React from "react";
import FooterOne from "../common/footer/FooterOne";
import HeaderFour from "../common/header/HeaderFour";
import SEO from "../common/SEO";
import BannerFive from "../component/banner/BannerFive";
import AboutFive from "../component/about/AboutFive";
import { useTranslation } from "react-i18next";

import { useArrayTranslations } from "../hooks/useArrayTranslations";
import Cards from "../common/cards/Cards";
import MemberCard from "../common/cards/MemberCard";
import useAssets from "../hooks/useAssests";

const Governance = () => {
  const { t } = useTranslation("governance");

  const { getTranslations } = useArrayTranslations("boards_members");

  const items = getTranslations();

  const { assets } = useAssets("governance");

  return (
    <>
      <SEO title={t("governance")} />
      <main className="main-wrapper">
        <HeaderFour />
        <BannerFive show_more={false} bk_image={assets.hero_image} />
        <div className="section section-padding container">
          <div className="row">
            <div className="col-12">
              <h3 className="title" style={{ color: "#AD942D" }}>
                {t("governance")}
              </h3>
              <p>{t("paragraph")}</p>
              <h4 className="title">{t("contributions_title")}</h4>
              <AboutFive
                title={t("contribution_subtitle_1")}
                paragraph={t("contribution_paragraph_1")}
              />
              <AboutFive
                title={t("contribution_subtitle_2")}
                paragraph={t("contribution_paragraph_2")}
              />
            </div>
          </div>
          {items.length > 0 && (
            <div
              className="row"
              style={{
                position: "relative",
                top: "40px",
              }}
            >
              <div className="col-12">
                <h4 className="title">{t("lamar_boards")}</h4>

                <Cards data={items} CardComponent={MemberCard} />
              </div>
            </div>
          )}
        </div>
        <FooterOne parentClass="" />
      </main>
    </>
  );
};

export default Governance;
