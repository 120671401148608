import React from 'react';
import { Link } from 'react-router-dom';
import { slugify } from '../../../utils';
import {FaAngleLeft } from "react-icons/fa";


const PropOne = ({projectStyle, portfolio}) => {
	
    return (
		<>
			<div className={`project-grid ${projectStyle}`}>
				<div className="">
					<img src={process.env.PUBLIC_URL + portfolio.image} alt="icon" />
				</div>
				<div className="content">
				<h5 className="title"> 
					{portfolio.title}
				</h5>
				<span className="subtitle"  style={{
					color : '#AD942D'
				}}>
					{portfolio.category.map((cat, i) => (
						<span key={i}>{cat}</span>
					))}
				</span>
				<Link
				to={'#'}
					style={{
						fontSize: '17px',
						marginTop: '30px',
						fontStyle: 'bold',
						display: 'block',
						color: 'black'
					
					}}
				>
					أنقر هنا لمعرفة المزيد 
					<FaAngleLeft />
				</Link>
				</div>
			</div>
		</>
    )
}

export default PropOne;