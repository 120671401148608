import React from "react";
import { Link } from "react-router-dom";
import {
  FaFacebookF,
  FaPinterestP,
  FaLinkedin,
  FaInstagram,
  FaVimeoV,
  FaDribbble,
  FaBehance,
  FaEnvelopeOpen,
  FaTiktok,
  FaYoutube,
  FaWhatsapp,
} from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import ServiceData from "../../data/service/ServiceMain.json";
import { useTranslation } from "react-i18next";
import useAssets from "../../hooks/useAssests";
import { IMAGES_URL } from "../../utils/constants";
import useArray from "../../hooks/useArray";

const getServiceData = ServiceData;

const FooterOne = ({ parentClass }) => {
  const { t } = useTranslation("footer");

  const { assets } = useAssets("footer");

  const { data } = useArray("social_links");

  const social_links_exists =
    data.facebook || data.whatsapp  || data.linkedin ;

  return (
    <footer className={`footer-area ${parentClass}`}>
      <div className="container p-4">
        <div className="footer-main">
          <div className="row">
            {social_links_exists && (
              <div className="col-xl-4 col-lg-5">
                <div className="footer-widget border-end">
                  <div className="footer-newsletter">
                    <img src={IMAGES_URL + assets.logo} alt="Logo" />
                    <div className="footer-social-link">
                      <ul className="list-unstyled">
                        <li>
                          {data.facebook  && (
                            <a href={data.facebook}>
                              <FaFacebookF />
                            </a>
                          )}
                        </li>
                        {data.linkedin  && (
                          <li>
                            <a href={data.linkedin}>
                              <FaLinkedin />
                            </a>
                          </li>
                        )}
                        {data.whatsapp && (
                          <li>
                            <a  href={`https://wa.me/${
                    data.whatsapp
                  }`}
                  target="_blank"
                  rel="noopener noreferrer">
                              <FaWhatsapp />
                            </a>
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div
              className={
                social_links_exists
                  ? "col-xl-8 col-lg-7"
                  : "col-xl-12 col-lg-12"
              }
            >
              <div className="row">
                <div className="col">
                  <div className="footer-widget">
                    <h6 className="widget-title">{t("important_links")}</h6>
                    <div className="footer-menu-link">
                      <ul className="list-unstyled">
                        <li>
                          <Link to={process.env.PUBLIC_URL + "/our-legacy"}>
                            {t("our_legacy")}
                          </Link>
                        </li>
                        <li>
                          <Link to={process.env.PUBLIC_URL + "/our-company"}>
                            {t("our_company")}
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="footer-widget">
                    <h6 className="widget-title">{t("who_are_we")}</h6>
                    <div className="footer-menu-link">
                      <ul className="list-unstyled">
                        <li>
                          <Link to={process.env.PUBLIC_URL + "/details"}>
                            {t("about_us")}
                          </Link>
                        </li>
                        <li>
                          <Link to={process.env.PUBLIC_URL + "/our-vision"}>
                            {t("our_vision")}
                          </Link>
                        </li>
                        <li>
                          <Link to={process.env.PUBLIC_URL + "/governance"}>
                            {t("governance")}
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="col">
                  <div className="footer-widget">
                    <h6 className="widget-title">{t("contact_us")}</h6>
                    <div className="footer-menu-link">
                      <ul className="list-unstyled">
                        <li>
                          <Link to={process.env.PUBLIC_URL + "/contact"}>
                            {t("contact_us")}
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <h6 style={{ textAlign: "center" }}>{t("copyrights")}</h6>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterOne;
