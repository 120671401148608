import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderFour from '../common/header/HeaderFour';
import SEO from '../common/SEO';
import BannerFive from '../component/banner/BannerFive';
import AboutSix from '../component/about/AboutSix';
import { useTranslation } from 'react-i18next';
import useAssets from '../hooks/useAssests';


const OurLegacy = () => {
    const { t } = useTranslation('our_legacy');
    const { assets } = useAssets('our_legacy');


    return (
        <>
            <SEO title={t('our_legacy')} />
            <main className="main-wrapper">
                <HeaderFour />
                <BannerFive title={t('title')}
                    subtitle={t('ceo')}
                    pargraph={t('paragraph1')}
                    pargraph2={t('paragraph2')}
                    show_more={false}
                    bk_image={assets.hero_image}
                    />
                <div className='section section-padding container'>
                    <div className='row'>
                        <AboutSix title={t('our_legacy')} paragraph={t('our_legacy_paragraph')}
                        />

                    </div>
                </div>
                <FooterOne parentClass="" />
            </main>
        </>
    )
}

export default OurLegacy;