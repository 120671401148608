import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Logo from '../../elements/logo/Logo';
import Nav from '../../common/header/Nav';
import StickyHeader from './StickyHeader';
import { Link } from 'react-router-dom';
import SwitcherHeader from '../../elements/switcher/SwitcherHeader';
import MobileMenu from './MobileMenu';

import useAssets from '../../hooks/useAssests';



const HeaderFour = ({ dark }) => {

    const { i18n } = useTranslation();

    const { assets } = useAssets('navbar')


    const [activeLanguage, setActiveLanguage] = useState(i18n.language);


    const handleLanguageChange = (language) => {
        i18n.changeLanguage(language);
        document.documentElement.dir = language === 'ar' ? 'rtl' : 'ltr';
        setActiveLanguage(language);
    };

    const sticky = StickyHeader(100);

    const MobileMenuHandler = () => {
        document.querySelector('.mobilemenu-popup').classList.toggle("show");
        document.querySelector('body').classList.toggle("mobilemenu-show");

        var elements = document.querySelectorAll('.mobilemenu-popup .menu-item-has-children > a');

        for (var i in elements) {
            if (elements.hasOwnProperty(i)) {
                elements[i].onclick = function () {
                    this.parentElement.querySelector('.axil-submenu').classList.toggle("active");
                    this.classList.toggle("open");
                }
            }
        }
    }

    useEffect(() => {
        document.documentElement.dir = activeLanguage === 'ar' ? 'rtl' : 'ltr';
        if (activeLanguage === 'ar') {
            document.body.classList.add('rtl');
        } else {
            document.body.classList.remove('rtl');
        }
    }, [activeLanguage]);


    return (
        <>
            <header className="header axil-header header-style-4">
                <div className={`axil-mainmenu ${sticky ? "axil-sticky" : ""}`}>
                    <div className="container">
                        <div className="header-navbar">
                            <div className="header-logo">
                                <Logo limage={assets.logo}
                                    simage={assets.logo}
                                />
                            </div>
                            <div className="header-main-nav">
                                <Nav dark={dark} />
                            </div>
                            <div className="header-action">
                                <ul className="list-unstyled">
                                    <div className="language-switcher">
                                        <div className="language-switcher" style={{ display: 'flex', alignItems: 'center' }}>
                                            <button onClick={() => handleLanguageChange('en')} style={{ background: 'transparent', border: 'none', cursor: 'pointer', color: "white", padding: '0 15px', paddingBottom: '4px', borderBottom: activeLanguage === 'en' ? '1px solid white' : 'none' }}>En</button>
                                            <button onClick={() => handleLanguageChange('ar')} style={{
                                                background: 'transparent',
                                                border: 'none',
                                                cursor: 'pointer',
                                                color: "white",
                                                padding: '0 15px',
                                                paddingBottom: '9px',
                                                position: 'relative',
                                                bottom: '1px',
                                                borderBottom: activeLanguage === 'ar' ? '1px solid white' : 'none'
                                            }}>ع</button>
                                        </div>
                                        {/* <li className="header-btn">
                                            <Link to={process.env.PUBLIC_URL + "/contact"} className="axil-btn btn-fill-white nav_btn_color">تواصل معنا</Link>
                                        </li> */}

                                    </div>
                                    <li className="mobile-menu-btn sidemenu-btn d-lg-none d-block">
                                        <button className="btn-wrap" onClick={MobileMenuHandler}>
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                        </button>
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <MobileMenu MobileHandler={MobileMenuHandler} />
        </>
    )
}

export default HeaderFour;