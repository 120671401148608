import React, { useState } from 'react';
import ProjectPropThree from './itemProp/ProjectPropThree';




const ProjectFive = ({ colSize, parentClass, perPageShow, page }) => {
    let AllData = [];
    if (page === 'our-vision') {
         AllData = [
            {
                "id": 1,
                "image": "/images/project/project-1.png",
                "title": "التنوع",
                "excerpt": "وهي منشأة غير ربحية تختص بإنتاج الملابس بهدف اجتماعي تنموي. تأسست الشركة من قبل مجموعة لمار..",
                "body": ["From the designers and engineers who are creating the next generation of web and mobile experiences, to anyone putting a website together for the first time. We provide elegant solutions that set new standards for online publishing.", "Digital technology has made our world more transparent and interconnected, posing new challenges and opportunities for every business. A holistic, user-centric perspective is what truly sets one apart."]
            },
            {
                "id": 2,
                "image": "/images/project/project-1.png",
                "title": "الاحترام",
                "excerpt": "وهي منشأة غير ربحية تختص بإنتاج الملابس بهدف اجتماعي تنموي. تأسست الشركة من قبل مجموعة لمار.",
                "body": ["From the designers and engineers who are creating the next generation of web and mobile experiences, to anyone putting a website together for the first time. We provide elegant solutions that set new standards for online publishing.", "Digital technology has made our world more transparent and interconnected, posing new challenges and opportunities for every business. A holistic, user-centric perspective is what truly sets one apart."]
            },
            {
                "id": 3,
                "image": "/images/project/project-1.png",
                "title": "التميز",
                "excerpt": "وهي منشأة غير ربحية تختص بإنتاج الملابس بهدف اجتماعي تنموي. تأسست الشركة من قبل مجموعة لمار.",
                "body": ["From the designers and engineers who are creating the next generation of web and mobile experiences, to anyone putting a website together for the first time. We provide elegant solutions that set new standards for online publishing.", "Digital technology has made our world more transparent and interconnected, posing new challenges and opportunities for every business. A holistic, user-centric perspective is what truly sets one apart."]
            },
            {
                "id": 4,
                "image": "/images/project/project-1.png",
                "title": "التنوع",
                "excerpt": "وهي منشأة غير ربحية تختص بإنتاج الملابس بهدف اجتماعي تنموي. تأسست الشركة من قبل مجموعة لمار.",
                "body": ["From the designers and engineers who are creating the next generation of web and mobile experiences, to anyone putting a website together for the first time. We provide elegant solutions that set new standards for online publishing.", "Digital technology has made our world more transparent and interconnected, posing new challenges and opportunities for every business. A holistic, user-centric perspective is what truly sets one apart."]
            },
            {
                "id": 5,
                "image": "/images/project/project-1.png",
                "title": "الاحترام",
                "excerpt": "وهي منشأة غير ربحية تختص بإنتاج الملابس بهدف اجتماعي تنموي. تأسست الشركة من قبل مجموعة لمار.",
                "body": ["From the designers and engineers who are creating the next generation of web and mobile experiences, to anyone putting a website together for the first time. We provide elegant solutions that set new standards for online publishing.", "Digital technology has made our world more transparent and interconnected, posing new challenges and opportunities for every business. A holistic, user-centric perspective is what truly sets one apart."]
            },
            {
                "id": 6,
                "image": "/images/project/project-1.png",
                "title": "التميز",
                "category": ["مكة المكرمة"],
                "excerpt": "وهي منشأة غير ربحية تختص بإنتاج الملابس بهدف اجتماعي تنموي. تأسست الشركة من قبل مجموعة لمار.",
                "body": ["From the designers and engineers who are creating the next generation of web and mobile experiences, to anyone putting a website together for the first time. We provide elegant solutions that set new standards for online publishing.", "Digital technology has made our world more transparent and interconnected, posing new challenges and opportunities for every business. A holistic, user-centric perspective is what truly sets one apart."]
            }
        ];
    } else if(page == 'governance') {
         AllData = [
            {
                "id": 1,
                "image": "/images/project/project-1.png",
                "title": "عبدالله الشهراني",
                role: "نائب الرئيس للشركات، عضو مجلس الإدارة",
                "arrowDown": true,
                "excerpt": "عبدالله الشهراني هو نائب الرئيس للشركات في شركة لمار وعضو في مجلس إدارتها. وهو المشرف على العلاقات المصرفية والخزينة وإدارة شركات مجموعة لمار؛ كما يعمل في العديد من مجالس الإدارات الفرعية بالشركة. يحمل الشهراني درجة البكالوريوس في إدارة الأعمال من جامعة الملك عبد العزيز ودرجة الماجستير في إدارة الأعمال من جامعة هال بالمملكة المتحدة.",
                "body": ["From the designers and engineers who are creating the next generation of web and mobile experiences, to anyone putting a website together for the first time. We provide elegant solutions that set new standards for online publishing.", "Digital technology has made our world more transparent and interconnected, posing new challenges and opportunities for every business. A holistic, user-centric perspective is what truly sets one apart."]
            },
            {
                "id": 2,
                "image": "/images/project/project-1.png",
                "title": "عبدالله الشهراني",
                role: "نائب الرئيس للشركات، عضو مجلس الإدارة",
                "arrowDown": true,
                "excerpt": "عبدالله الشهراني هو نائب الرئيس للشركات في شركة لمار وعضو في مجلس إدارتها. وهو المشرف على العلاقات المصرفية والخزينة وإدارة شركات مجموعة لمار؛ كما يعمل في العديد من مجالس الإدارات الفرعية بالشركة. يحمل الشهراني درجة البكالوريوس في إدارة الأعمال من جامعة الملك عبد العزيز ودرجة الماجستير في إدارة الأعمال من جامعة هال بالمملكة المتحدة.",
                "body": ["From the designers and engineers who are creating the next generation of web and mobile experiences, to anyone putting a website together for the first time. We provide elegant solutions that set new standards for online publishing.", "Digital technology has made our world more transparent and interconnected, posing new challenges and opportunities for every business. A holistic, user-centric perspective is what truly sets one apart."]
            },
            {
                "id": 3,
                "image": "/images/project/project-1.png",
                "title": "عبدالله الشهراني",
                role: "نائب الرئيس للشركات، عضو مجلس الإدارة",
                "arrowDown": true,
                "excerpt": "عبدالله الشهراني هو نائب الرئيس للشركات في شركة لمار وعضو في مجلس إدارتها. وهو المشرف على العلاقات المصرفية والخزينة وإدارة شركات مجموعة لمار؛ كما يعمل في العديد من مجالس الإدارات الفرعية بالشركة. يحمل الشهراني درجة البكالوريوس في إدارة الأعمال من جامعة الملك عبد العزيز ودرجة الماجستير في إدارة الأعمال من جامعة هال بالمملكة المتحدة.",
                "body": ["From the designers and engineers who are creating the next generation of web and mobile experiences, to anyone putting a website together for the first time. We provide elegant solutions that set new standards for online publishing.", "Digital technology has made our world more transparent and interconnected, posing new challenges and opportunities for every business. A holistic, user-centric perspective is what truly sets one apart."]
            }
        ];
    } else if(page == 'our-company') {
        AllData = [
            {
                "id": 1,
                "image": "/images/project/project-1.png",
                "title": "لمار للصناعات المتحدة",
                "visit_site" : true,
                "arrowDown": true,
                "excerpt": "شركة تابعة لشركة لمار المحدودة تعمل من خلال أقسام الأعمال الاستراتيجية وتقدم خدمات عالية الجودة في العديد من القطاعات الصناعية.",
                "body": ["From the designers and engineers who are creating the next generation of web and mobile experiences, to anyone putting a website together for the first time. We provide elegant solutions that set new standards for online publishing.", "Digital technology has made our world more transparent and interconnected, posing new challenges and opportunities for every business. A holistic, user-centric perspective is what truly sets one apart."]
            },
            {
                "id": 2,
                "image": "/images/project/project-1.png",
                "title": "لمار للصناعات المتحدة",
                "visit_site" : true,
                "arrowDown": true,

                "excerpt": "شركة تابعة لشركة لمار المحدودة تعمل من خلال أقسام الأعمال الاستراتيجية وتقدم خدمات عالية الجودة في العديد من القطاعات الصناعية.",
                "body": ["From the designers and engineers who are creating the next generation of web and mobile experiences, to anyone putting a website together for the first time. We provide elegant solutions that set new standards for online publishing.", "Digital technology has made our world more transparent and interconnected, posing new challenges and opportunities for every business. A holistic, user-centric perspective is what truly sets one apart."]
            },
            {
                "id": 3,
                "image": "/images/project/project-1.png",
                "title": "لمار للصناعات المتحدة",
                "visit_site" : true,
                "arrowDown": true,

                "excerpt": "شركة تابعة لشركة لمار المحدودة تعمل من خلال أقسام الأعمال الاستراتيجية وتقدم خدمات عالية الجودة في العديد من القطاعات الصناعية.",
                "body": ["From the designers and engineers who are creating the next generation of web and mobile experiences, to anyone putting a website together for the first time. We provide elegant solutions that set new standards for online publishing.", "Digital technology has made our world more transparent and interconnected, posing new challenges and opportunities for every business. A holistic, user-centric perspective is what truly sets one apart."]
            },
            {
                "id": 4,
                "image": "/images/project/project-1.png",
                "title": "لمار للصناعات المتحدة",
                "visit_site" : true,
                "arrowDown": true,

                "excerpt": "شركة تابعة لشركة لمار المحدودة تعمل من خلال أقسام الأعمال الاستراتيجية وتقدم خدمات عالية الجودة في العديد من القطاعات الصناعية.",
                "body": ["From the designers and engineers who are creating the next generation of web and mobile experiences, to anyone putting a website together for the first time. We provide elegant solutions that set new standards for online publishing.", "Digital technology has made our world more transparent and interconnected, posing new challenges and opportunities for every business. A holistic, user-centric perspective is what truly sets one apart."]
            },
            {
                "id": 5,
                "image": "/images/project/project-1.png",
                "title": "لمار للصناعات المتحدة",
                "visit_site" : true,
                "arrowDown": true,

                "excerpt": "شركة تابعة لشركة لمار المحدودة تعمل من خلال أقسام الأعمال الاستراتيجية وتقدم خدمات عالية الجودة في العديد من القطاعات الصناعية.",
                "body": ["From the designers and engineers who are creating the next generation of web and mobile experiences, to anyone putting a website together for the first time. We provide elegant solutions that set new standards for online publishing.", "Digital technology has made our world more transparent and interconnected, posing new challenges and opportunities for every business. A holistic, user-centric perspective is what truly sets one apart."]
            },
            {
                "id": 6,
                "image": "/images/project/project-1.png",
                "title": "لمار للصناعات المتحدة",
                "visit_site" : true,
                "arrowDown": true,

                "excerpt": "شركة تابعة لشركة لمار المحدودة تعمل من خلال أقسام الأعمال الاستراتيجية وتقدم خدمات عالية الجودة في العديد من القطاعات الصناعية.",
                "body": ["From the designers and engineers who are creating the next generation of web and mobile experiences, to anyone putting a website together for the first time. We provide elegant solutions that set new standards for online publishing.", "Digital technology has made our world more transparent and interconnected, posing new challenges and opportunities for every business. A holistic, user-centric perspective is what truly sets one apart."]
            }
        ];
    } else if(page == "social-responsibility") { 
        AllData = [
            {
                "id": 1,
                "image": "/images/project/project-1.png",
                "title": "  نمط",
                "visit_site" : true,
                "arrowDown": true,
                "excerpt": "وهي منشأة غير ربحية تختص بإنتاج الملابس بهدف اجتماعي تنموي. تأسست الشركة من قبل مجموعة لمار لتكون عبارة عن مبادرة استراتيجية للمسؤولية الاجتماع..",
                "body": ["From the designers and engineers who are creating the next generation of web and mobile experiences, to anyone putting a website together for the first time. We provide elegant solutions that set new standards for online publishing.", "Digital technology has made our world more transparent and interconnected, posing new challenges and opportunities for every business. A holistic, user-centric perspective is what truly sets one apart."]
            },
            {
                "id": 2,
                "image": "/images/project/project-1.png",
                "title": "  نمط",
                "visit_site" : true,
                "arrowDown": true,

                "excerpt": "وهي منشأة غير ربحية تختص بإنتاج الملابس بهدف اجتماعي تنموي. تأسست الشركة من قبل مجموعة لمار لتكون عبارة عن مبادرة استراتيجية للمسؤولية الاجتماع..",
                "body": ["From the designers and engineers who are creating the next generation of web and mobile experiences, to anyone putting a website together for the first time. We provide elegant solutions that set new standards for online publishing.", "Digital technology has made our world more transparent and interconnected, posing new challenges and opportunities for every business. A holistic, user-centric perspective is what truly sets one apart."]
            },
            {
                "id": 3,
                "image": "/images/project/project-1.png",
                "title": "  نمط",
                "visit_site" : true,
                "arrowDown": true,

                "excerpt": "وهي منشأة غير ربحية تختص بإنتاج الملابس بهدف اجتماعي تنموي. تأسست الشركة من قبل مجموعة لمار لتكون عبارة عن مبادرة استراتيجية للمسؤولية الاجتماع..",
                "body": ["From the designers and engineers who are creating the next generation of web and mobile experiences, to anyone putting a website together for the first time. We provide elegant solutions that set new standards for online publishing.", "Digital technology has made our world more transparent and interconnected, posing new challenges and opportunities for every business. A holistic, user-centric perspective is what truly sets one apart."]
            },
            {
                "id": 4,
                "image": "/images/project/project-1.png",
                "title": "  نمط",
                "visit_site" : true,
                "arrowDown": true,

                "excerpt": "وهي منشأة غير ربحية تختص بإنتاج الملابس بهدف اجتماعي تنموي. تأسست الشركة من قبل مجموعة لمار لتكون عبارة عن مبادرة استراتيجية للمسؤولية الاجتماع..",
                "body": ["From the designers and engineers who are creating the next generation of web and mobile experiences, to anyone putting a website together for the first time. We provide elegant solutions that set new standards for online publishing.", "Digital technology has made our world more transparent and interconnected, posing new challenges and opportunities for every business. A holistic, user-centric perspective is what truly sets one apart."]
            },
        ]
    }else if(page == "media-center") {
        AllData = [
            {
                "id": 1,
                "image": "/images/project/project-1.png",
                "title": "  شعار لمار",
    "show" : true,                
                "body": ["From the designers and engineers who are creating the next generation of web and mobile experiences, to anyone putting a website together for the first time. We provide elegant solutions that set new standards for online publishing.", "Digital technology has made our world more transparent and interconnected, posing new challenges and opportunities for every business. A holistic, user-centric perspective is what truly sets one apart."]
            },
            {
                "id": 2,
                "image": "/images/project/project-1.png",
                "title": "  ارشادات الشعار",

                "show" : true,
                "body": ["From the designers and engineers who are creating the next generation of web and mobile experiences, to anyone putting a website together for the first time. We provide elegant solutions that set new standards for online publishing.", "Digital technology has made our world more transparent and interconnected, posing new challenges and opportunities for every business. A holistic, user-centric perspective is what truly sets one apart."]
            },
            {
                "id": 3,
                "image": "/images/project/project-1.png",
                "title": "  ملف الشركة - اللغة الانجليزية",
                "show" : true,
                "body": ["From the designers and engineers who are creating the next generation of web and mobile experiences, to anyone putting a website together for the first time. We provide elegant solutions that set new standards for online publishing.", "Digital technology has made our world more transparent and interconnected, posing new challenges and opportunities for every business. A holistic, user-centric perspective is what truly sets one apart."]
            },
        ]
    }else{
        AllData = [
            {
                "id": 1,
                "image": "/images/project/project-1.png",
                "news_description" : ` أبرمت شركة لمار للصناعات المتحدة عقدًا بقيمة 100 مليون ريال سعودي لتطوير مركز تجارب مخصص لكبار الشخصيات لشركة أرامكو السعودية، ما يسلط الضوء على تاريخ أرامكو السعودية ورؤيتها المستقبلية. ويقع المشروع في الطابقين الثاني عشر والثالث عشر من برج أرامكو في مركز الملك عبد الله المالي "KAFD" بالرياض، وقد انطلق في أكتوبر 2022 ووُضعت لبناته الأخيرة في شهر مايو من هذا العام.<br>
    تولت شركة لمار للصناعات المتحدة المسؤولية عن أعمال التركيب والاختبار والتشغيل الكامل لجميع الأنظمة المدنية وأعمال الميكانيكا والكهرباء والسباكة وأنظمة المشروع التي تعمل بالتيار المنخفض، وشمل ذلك التجهيزات الداخلية التي تدمج مجموعة من التقنيات الرائدة لإنشاء مركز تجارب آسر بجودة عالمية.<br>
    يعد المشروع أحد أفضل المشاركات التي تم اختيارها للتنافس على جائزة في فئة المتاحف في حفل توزيع جوائز موندو لعام 2024 في لاس فيغاس.`,
                "news_title" : "لمار للصناعات المتحدة تُنجز مشروع إنشاء مركز زوار لكبار الشخصيات لشركة أرامكو السعودية",
                "news_date" : "يونيو 2024"
            },
            {
                "id": 2,
                "image": "/images/project/project-1.png",
                "news_title" : "لمار للصناعات المتحدة تُنجز مشروع إنشاء مركز زوار لكبار الشخصيات لشركة أرامكو السعودية",
                "news_description" : ` أبرمت شركة لمار للصناعات المتحدة عقدًا بقيمة 100 مليون ريال سعودي لتطوير مركز تجارب مخصص لكبار الشخصيات لشركة أرامكو السعودية، ما يسلط الضوء على تاريخ أرامكو السعودية ورؤيتها المستقبلية. ويقع المشروع في الطابقين الثاني عشر والثالث عشر من برج أرامكو في مركز الملك عبد الله المالي "KAFD" بالرياض، وقد انطلق في أكتوبر 2022 ووُضعت لبناته الأخيرة في شهر مايو من هذا العام.<br>
                تولت شركة لمار للصناعات المتحدة المسؤولية عن أعمال التركيب والاختبار والتشغيل الكامل لجميع الأنظمة المدنية وأعمال الميكانيكا والكهرباء والسباكة وأنظمة المشروع التي تعمل بالتيار المنخفض، وشمل ذلك التجهيزات الداخلية التي تدمج مجموعة من التقنيات الرائدة لإنشاء مركز تجارب آسر بجودة عالمية.<br>
                يعد المشروع أحد أفضل المشاركات التي تم اختيارها للتنافس على جائزة في فئة المتاحف في حفل توزيع جوائز موندو لعام 2024 في لاس فيغاس.`,
                "news_date" : "يونيو 2024"

            },
            {
                "id": 3,
                "image": "/images/project/project-1.png",
                "news_title" : "لمار للصناعات المتحدة تُنجز مشروع إنشاء مركز زوار لكبار الشخصيات لشركة أرامكو السعودية",
                "news_date" : "يونيو 2024"
            },
            {
                "id": 4,
                "image": "/images/project/project-1.png",
                "news_title" : "لمار للصناعات المتحدة تُنجز مشروع إنشاء مركز زوار لكبار الشخصيات لشركة أرامكو السعودية",
                "news_date" : "يونيو 2024"
            },
            {
                "id": 5,
                "image": "/images/project/project-1.png",
                "news_title" : "لمار للصناعات المتحدة تُنجز مشروع إنشاء مركز زوار لكبار الشخصيات لشركة أرامكو السعودية",
                "news_date" : "يونيو 2024"
            },
            {
                "id": 6,
                "image": "/images/project/project-1.png",
                "news_title" : "لمار للصناعات المتحدة تُنجز مشروع إنشاء مركز زوار لكبار الشخصيات لشركة أرامكو السعودية",
                "news_date" : "يونيو 2024"
            },
            {
                "id": 7,
                "image": "/images/project/project-1.png",
                "news_title" : "لمار للصناعات المتحدة تُنجز مشروع إنشاء مركز زوار لكبار الشخصيات لشركة أرامكو السعودية",
                "news_date" : "يونيو 2024"
            },
            {
                "id": 8,
                "image": "/images/project/project-1.png",
                "news_title" : "لمار للصناعات المتحدة تُنجز مشروع إنشاء مركز زوار لكبار الشخصيات لشركة أرامكو السعودية",
                "news_date" : "يونيو 2024"
            },
            {
                "id": 9,
                "image": "/images/project/project-1.png",
                "news_title" : "لمار للصناعات المتحدة تُنجز مشروع إنشاء مركز زوار لكبار الشخصيات لشركة أرامكو السعودية",
                "news_date" : "يونيو 2024"
            },
        ]
    }
    const [projects] = useState(AllData.slice(0, 16));
    const [pageNumber, setPageNumber] = useState(0);

    const projectPerPage = perPageShow ? perPageShow : 9;
    const pageVisited = pageNumber * projectPerPage;

    const displayProjects = projects.slice(pageVisited, pageVisited + projectPerPage)
        .map((data) => (
            <div className="col" key={data.id}>
                <ProjectPropThree projectStyle="" portfolio={data} />
            </div>
        ))

    const pageCount = Math.ceil(projects.length / projectPerPage);

    const changePage = ({ selected }) => {
        setPageNumber(selected);
    }

    return (
        <div className={`section  pt_md--80 pt_sm--60 ${parentClass ? parentClass : ""}`}>
            <div className="container-fluid plr--30">
                <div className={`row row-cols-1 ${colSize ? colSize : "row-cols-sm-2 row-cols-xl-3"}`}>
                    {displayProjects}
                </div>

            </div>
        </div>
    )
}

export default ProjectFive;