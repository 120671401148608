import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderFour from '../common/header/HeaderFour';
import SEO from '../common/SEO';

import BannerFive from '../component/banner/BannerFive';
import SectionTitle from '../elements/section-title/SectionTitle';
import ServicePropOne from '../component/service/ServicePropOne';
import ProjectOne_2 from '../component/project/ProjectOne_2';


const AboutUs = () => {

    return (
        <>
            <SEO title="About us" />
            <main className="main-wrapper">
                <HeaderFour />
                <BannerFive title=' مشروع النقل العام بالحافلات' subtitle='مكة المكرمة' pargraph='في لمار، رؤيتنا وقيمنا هما النبراس الذي يرشدنا في اعمالنا وثقافتنا.في لمار، رؤيتنا وقيمنا هما النبراس الذي يرشدنا في اعمالنا وثقافتنا.' />
                <div className="section section-padding">
                    <div className="container"

                    >
                        <SectionTitle
                            subtitle="عن لمار"
                            title="ما نقدمه لك"
                            description=""
                            textAlignment="heading-left mb--20 mb_md--70"
                            textColor=""
                        />
                        <div className="row"

                        >
                            <ServicePropOne
                                colSize="col-lg-4" serviceStyle="service-style-2" itemShow="3" marginTop="yes" />
                        </div>
                    </div>


                </div>
                <ProjectOne_2 colSize="col-xl-4 col-md-6" columnGap="row-15" parentClass="bg-color-light" />

                <FooterOne parentClass="" />
            </main>
        </>
    )
}

export default AboutUs;