import { IMAGES_URL } from "../../utils/constants";
import { FaAngleDown, FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import { Link } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

const CompanyCard = ({ data: company }) => {
    const { i18n } = useTranslation();
    const { image ,href_link } = company;
    const { title, paragraph, href  } = company?.translations?.[0] ?? {
        title: '',
        paragraph: '',
        href: ''
    };

    const getLinkIcon = () => {
        return i18n.language === 'en' ? <FaAngleRight style={{ paddingInlineStart: '5px' }} /> : <FaAngleLeft style={{ paddingInlineStart: '5px' }} />;
    };

    return (
        <div className="project-grid">
            <div>
                <img src={IMAGES_URL + image} alt="icon" />
                <div className="content">
                    <h5 className="title">
                        {title}
                        <FaAngleDown style={{ float: 'inline-end' }} />
                    </h5>

                    <p>
                        {paragraph}
                    </p>

                    <a
                        style={{
                            color: 'black',

                        }}
                        href={href_link} className="axil-button button-alt"
                    >
                        {href}
                        {getLinkIcon()}
                        {/* <FaAngleLeft style={{ paddingInlineStart: '5px' }} /> */}

                    </a>
                </div>
            </div>
        </div>
    );
};

export default CompanyCard;