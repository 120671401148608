import { IMAGES_URL } from "../../utils/constants"
import { FaAngleDown } from 'react-icons/fa';

import { useTranslation } from 'react-i18next';


const NewsCard = ({ data: news }) => {

    const { i18n } = useTranslation();

    const { image } = news
    const { title, date, paragraph } = news?.translations?.[0] ?? {
        title: '',
        date: Date.now(),
        paragraph: ''
    }

    const formattedDate = new Date(date).toLocaleDateString(i18n.language, { month: 'long', year: 'numeric' });

    return (
        <div className="project-grid">
            <div>
                <h5 className="title">
                    {title}
                </h5>
                <span style={{
                    padding: '10px',
                    paddingBottom: ' 20px'
                }}>

                    {formattedDate}
                    <FaAngleDown style={{
                        paddingInlineStart: '10px',
                        fontSize: '20px',
                        position: 'relative',
                        bottom: '2px',
                    }} />
                </span>


            </div>
            <div >
                <img src={IMAGES_URL + image} alt="icon" />
                <div className="content">
                    {paragraph &&
                        <p style={{
                            color: '#8F8F8F'
                        }} dangerouslySetInnerHTML={{ __html: paragraph }}></p>
                    }

                </div>
            </div>



        </div>
    );
}

export default NewsCard
