import React, {useState, useEffect} from 'react';
import ProjectPropOne from './itemProp/ProjectPropOne';
import SectionTitle from '../../elements/section-title/SectionTitle';
import ProjectData from "../../data/project/ProjectData.json";


const filters = [
	{
		id: 1,
		label: "All Works",
    },
    {
		id: 2,
		label: "Branding",
    },
    {
		id: 3,
		label: "Mobile",
    }
];


const AllData = ProjectData;


const ProjectOne = ({parentClass, colSize, itemShow, columnGap}) => {

	const [getAllItems] = useState(AllData);
	const [visibleItems, setVisibleItems] = useState([]);
	

	useEffect(() => {
		setVisibleItems(getAllItems);
    }, []);



    return (
		<>
			<div className={`section section-padding-2 ${parentClass ? parentClass : ""}`}>
                <div className="container">
                <SectionTitle 
                        subtitle="أبرز النقاط"
                        title="مشاريعنا"
                        textAlignment="heading-left mb--40"
                        textColor=""
                    />
                 
					<div className={`row ${columnGap ? columnGap : "row-35"}`}>
						{visibleItems.map((data) => (
							<div className={colSize ? colSize : "col-md-6"} key={data.id}>
								<ProjectPropOne projectStyle="" portfolio={data}/>
							</div>
						))}
					</div>

                </div>
              
            </div>
		</>
    )
}

export default ProjectOne;