import React from 'react';


const AboutSix = ({ title, paragraph}) => {
    return (
        <div className="container mb-3">
            <div className="row align-items-center">
                <div className="col-lg-5">
                    <div className="about-team">
                        <div className="section-heading heading-left">
                            <span className="subtitle" style={{
                                color: 'black'
                            }}>{title}</span>
      <p dangerouslySetInnerHTML={{ __html: paragraph }}></p>
            
                        </div>
                    </div>
                </div>
                <div className="col-lg-7">
                    <div className="about-team">
                        <div className="thumbnail">
                            <img src='https://placehold.co/600x400' alt="thumbnail" />
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default AboutSix;