import React from 'react';
import { Link } from 'react-router-dom';
import { IMAGES_URL } from '../../utils/constants';


const Logo = ({ limage, simage }) => {
    return (
        <Link to={process.env.PUBLIC_URL + "/"}>
            <img className="light-version-logo" src={IMAGES_URL + limage} alt="logo" />
            <img className="sticky-logo" src={IMAGES_URL + simage} alt="logo" />
        </Link>
    )
}


export default Logo;