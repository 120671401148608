import React from 'react'
import PropTypes from "prop-types";
import { useTranslation } from 'react-i18next';


const SEO = ({ title }) => {
    const { t } = useTranslation('seo')

    return (
        <>
            <meta charSet="utf-8" />
            <title>{title}</title>
            <meta name="robots" content="noindex, follow" />
            <meta name="description" content={t('description')} />
            <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
            <meta name="keywords" content={t('keywords')} />
        </>
    )
}
SEO.propTypes = {
    title: PropTypes.string
};

export default SEO;