import React from 'react';

import { FaAngleDown, FaAngleLeft } from 'react-icons/fa';
import { Link } from 'react-router-dom';


const ProjectPropThree = ({ projectStyle, portfolio }) => {

	return (
		<>
			<div className={`project-grid ${projectStyle}`}>
				<div>
					{portfolio.news_title && <h5 className="title">
						{portfolio.news_title}
					</h5>}
					{portfolio.news_date && <span style={{
						padding: '10px',
						paddingBottom: ' 20px'
					}}>

						{portfolio.news_date}
						<FaAngleDown style={{
							paddingInlineStart: '10px',
							fontSize: '20px',
							position: 'relative',
							bottom: '2px',
						}} />
					</span>}


				</div>
				<div className="">
					<img src={process.env.PUBLIC_URL + portfolio.image} alt="icon" />
				</div>
				{(!portfolio.news_title || (portfolio.news_title && portfolio.news_description)) && (
					<>
						<div className="content">
							<h5 className="title">
								{portfolio.title}
								{
									portfolio.arrowDown && <FaAngleDown style={{
										float: 'left',
									}} />

								}
							</h5>
							{portfolio.news_description && <p style={{
								color: '#8F8F8F'
							}} dangerouslySetInnerHTML={{ __html: portfolio.news_description }}></p>
							}
							{portfolio.role && <h6 style={{
								fontSize: '16px',
							}} >
								{portfolio.role}
							</h6>}
							<p >
								{portfolio.excerpt}
							</p>
							{portfolio.visit_site && <Link
								style={{
									color: 'black',

								}}
								to={"#"} className="axil-button button-alt"
							>
								قم بزيارة الموقع
								<FaAngleLeft style={{ paddingRight: '5px' }} />

							</Link>}
							{portfolio.show && <Link
								style={{
									color: 'black',

								}}
								to={"#"} className="axil-button button-alt"
							>
								عرض
								<FaAngleLeft style={{ paddingRight: '5px' }} />

							</Link>}


						</div>
					</>
				)}
			</div>
		</>
	)
}

export default ProjectPropThree;