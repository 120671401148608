const Cards = ({ data, CardComponent }) => {
    return (
        <div className="section  pt_md--80 pt_sm--60">
            <div className="container-fluid plr--30">
                <div className={"row row-cols-1 row-cols-sm-2 row-cols-xl-3"}>
                    {data.map((item, i) => (
                        <div key={i} className="col">
                            <CardComponent data={item} />
                        </div>
                    ))}
                </div>

            </div>
        </div>
    )
}

export default Cards
