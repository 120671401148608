import { createDirectus, rest, readItems } from '@directus/sdk';


const directus = createDirectus('https://dashboard.lamarcoholding.com').with(rest());

const getItems = async (collection, language = null) => {
    try {
        const response = await directus.request(
            readItems(collection, {
                deep: {
                    translations: {
                        _filter: {
                            _and: [
                                {
                                    languages_code: { _eq: language },
                                },
                            ],
                        },
                    },
                },
                fields: ['*', { translations: ['*'] }],
            }));

        return response
    } catch (error) {
        console.error('Error fetching translations:', error);
        return null;
    }
};

export default getItems;


