import { IMAGES_URL } from "../../utils/constants"
import { FaAngleDown } from 'react-icons/fa';


const MemberCard = ({ data: member }) => {

    const { image } = member
    const { name, position, about } = member?.translations?.[0] ?? {
        name: '',
        position: '',
        about: ''
    }

    return (
        <div className="project-grid">
            <div >
                <img src={IMAGES_URL + image} alt="icon" />
                <div className="content">
                    <h5 className="title">
                        {name}
                        <FaAngleDown style={{
                            float: "inline-end",
                        }} />

                    </h5>

                    <h6 style={{
                        fontSize: '16px',
                    }} >
                        {position}
                    </h6>
                    <p >
                        {about}
                    </p>
                </div>
            </div>



        </div>
    )
}

export default MemberCard
