import React from 'react';
import { useTranslation } from 'react-i18next';

import FooterOne from '../common/footer/FooterOne';
import HeaderFour from '../common/header/HeaderFour';
import SEO from '../common/SEO';
import BannerFive from '../component/banner/BannerFive';
import Cards from '../common/cards/Cards';
import CompanyCard from '../common/cards/CompanyCard';

import { useArrayTranslations } from '../hooks/useArrayTranslations';
import useAssets from '../hooks/useAssests';


const OurCompany = () => {

    const { t } = useTranslation('our_company');

    const { getTranslations } = useArrayTranslations('company_samples');
    const { assets } = useAssets('our_company');


    const items = getTranslations();



    return (
        <>
            <SEO title={t('our_company')} />
            <main className="main-wrapper">
                <HeaderFour />
                <BannerFive
                    bk_image={assets.hero_image}
                    //                     pargraph='
                    // لتحقيق تقدم حقيقي ، يحتاج المجتمع إلى شركات تملأ الفجوات الفريدة وتعالج احتياجات محددة. في المملكة العربية السعودية، بينما تسعى الحكومة جاهدة لتحقيق رؤية 2030 وإنشاء مدن جديدة، أصبح إنشاء حلول للمواطنين والأعمال تتسم بالكفاءة والفعالية منذ البداية أكثر أهمية من أي وقت مضى.
                    //                     '
                    pargraph={t('main_paragraph')}
                    show_more={false} />
                <div className='section section-padding container'>
                    <div className='row'>
                        <h3 style={{color : '#AD942D'}} className='title'>{t('title')}</h3>
                        <h5 className='title'>{t('our_company')}</h5>

                    </div>
                    <div className='row'>
                        <Cards data={items} CardComponent={CompanyCard} />
                    </div>
                </div>
                <FooterOne parentClass="" />
            </main>
        </>
    )
}

export default OurCompany;