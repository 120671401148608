import React from 'react';
import { Link } from 'react-router-dom';
import { FaAngleDown } from "react-icons/fa";

import { useTranslation } from 'react-i18next';
import useAssets from '../../hooks/useAssests';


const Nav = ({ dark }) => {
    const { t } = useTranslation(['navbar', 'our_vision']);





    return (
        <nav className="mainmenu-nav">
            <ul className="mainmenu">
                <li className="menu-item-has-children">
                    <Link to="#" >{t('who_are_we')}<FaAngleDown /></Link>
                    <ul className="axil-submenu">
                        <li><Link to={process.env.PUBLIC_URL + "/details"}> {t('about_us')}</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + "/our-vision"}> {t('our_vision')}</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + "/governance"}> {t('governance')}</Link></li>
                    </ul>
                </li>
                <li className="menu-item">
                    <Link to={process.env.PUBLIC_URL + "/our-legacy"}> {t('our_legacy')} </Link>
                </li>
                <li className="menu-item">
                    <Link to={process.env.PUBLIC_URL + "/our-company"}> {t('our_company')} </Link>
                </li>
                <li className="menu-item-has-children">
                    <Link to='#'>{t('media_center')} <FaAngleDown /></Link>
                    <ul className="axil-submenu">
                        <li><Link to={process.env.PUBLIC_URL + "/media-center"}>{t('media_kit')} </Link></li>
                        <li><Link to={process.env.PUBLIC_URL + "/latest-news"}>{t('news')}</Link></li>
                    </ul>
                </li>
                <li className="menu-item">
                    <Link to={process.env.PUBLIC_URL + "/contact"}> {t('contact_us')} </Link>
                </li>
            </ul>
        </nav>
    )
}

export default Nav;