import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const ServiceData = [
  {
    image: '/images/ico-1.png',
    title: 'cart_1_title',
    description: 'card_1_paragraph',
  },
  {
    image: '/images/ico-2.png',
    title: 'cart_2_title',
    description: 'card_2_paragraph',
  },
  {
    image: '/images/ico-1.png',
    title: 'cart_3_title',
    description: 'card_3_paragraph',
  },
];

const AllData = ServiceData;

const ServicePropOne = ({ colSize, serviceStyle, itemShow, marginTop }) => {
  const { t } = useTranslation();
  const MAX_LENGTH = 100;

  // Create a state for each service item to track if it's expanded or not
  const [expandedItems, setExpandedItems] = useState(
    Array(AllData.length).fill(false)
  );

  // Toggle the expanded state for a specific item
  const toggleShowMore = (index) => {
    setExpandedItems((prevState) =>
      prevState.map((item, i) => (i === index ? !item : item))
    );
  };

  // Function to determine the top margin based on index and prop
  const topMargin = (index) => {
    if (marginTop === 'yes') {
      if (index === 0) {
        return 'mt--0 mt_md--0';
      } else if (index === 1) {
        return 'mt--100 mt_md--0';
      } else {
        return 'mt--200';
      }
    } else {
      return '';
    }
  };

  return (
    <>
      {AllData.slice(0, itemShow).map((data, index) => (
        <div className={`${colSize} ${topMargin(index)}`} key={index}>
          <div className={`services-grid ${serviceStyle}`}>
            <div className="thumbnail">
              <img src={process.env.PUBLIC_URL + data.image} alt="icon" />
            </div>
            <div className="content">
              <h5 className="title">
                <Link to={'#'}>{t(data.title)}</Link>
              </h5>
              <p style={{ fontSize: '22px' }}>
                {expandedItems[index]
                  ? t(data.description)
                  : `${t(data.description).slice(0, MAX_LENGTH)}...`}
              </p>
              <button
                className="more-btn srv_more"
                onClick={() => toggleShowMore(index)}
              >
                {expandedItems[index] ? t('show_less') : t('click_for_more')}
              </button>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default ServicePropOne;
