import { IMAGES_URL } from "../../utils/constants";
import { FaAngleDown, FaAngleLeft, FaAngleRight } from 'react-icons/fa';

import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const MediaCard = ({ data: media }) => {
    const { i18n } = useTranslation();
    const { image } = media;
    const { title, href } = media?.translations?.[0] ?? {
        title: '',
        href: ''
    };

    const getLinkIcon = () => {
        return i18n.language === 'en' ? <FaAngleRight style={{ paddingInlineStart: '5px' }} /> : <FaAngleLeft style={{ paddingInlineStart: '5px' }} />;
    };

    return (
        <div className="project-grid">
            <div>
                <img src={IMAGES_URL + image} alt="icon" />
                <div className="content">
                    <h5 className="title">
                        {title}
                        <FaAngleDown style={{ float: 'inline-end' }} />
                    </h5>
                    <Link
                        style={{
                            color: 'black',

                        }}
                        to={"#"} className="axil-button button-alt"
                    >
                        {href}
                        {getLinkIcon()}
                        {/* <FaAngleLeft style={{ paddingInlineStart: '5px' }} /> */}

                    </Link>
                </div>
            </div>
        </div>
    );
};

export default MediaCard;